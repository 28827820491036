import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  binaryToDecimal,
  decimalToBinary,
  isBinary,
} from "../../../components/Script/binary"
import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  StaticButton,
} from "../../../StyleComponents/styles"
import { TabsPages } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Converter", link: "/units-converter/" },
  {
    name: "Code Converter",
    link: "/decimal-hexadecimal-binary-converter/",
  },
]
const seeAlsoArray = [
  "/ascii-and-binary-converter",
  "/ascii-code-converter",
  "/morse-code-conversion",
  "/binary-calculation",
  "/binary-decoder",
  "/binary-encoder",
]

function DecimalHexadecimalBinaryConverter(props) {
  let [Binary, setBinary] = useState("")
  let [Decimal, setDecimal] = useState("")
  let [Hexadecimal, setHexadecimal] = useState("")
  let [binaryError, setbinaryError] = useState(false)
  let [binaryResult, setbinaryResult] = useState("")
  let [decimalResult, setdecimalResult] = useState("")
  let [hexResult, sethexResult] = useState("")
  let [pageOpen, setPageOpen] = useState({
    binary: true,
    decimal: false,
    hex: false,
  })

  // /[^10]/g.test("101")
  function Binarychange(e) {
    setBinary(e.target.value)
  }
  function Decimalchange(e) {
    setDecimal(e.target.value)
  }
  function Hexadecimalchange(e) {
    setHexadecimal(e.target.value)
  }
  function checkIfBinary(e) {
    if (isBinary(e.target.value)) {
      setbinaryError(false)
    } else {
      setbinaryError(true)
    }
  }
  function decimalToB() {
    setdecimalResult(
      "Decimal : " + Decimal + ", Binary : " + decimalToBinary(Decimal)
    )
  }

  function binaryToD() {
    setbinaryResult(
      "Binary : " + Binary + ", Decimal : " + binaryToDecimal(Binary)
    )
  }

  function hexToDecimal() {
    var value = Hexadecimal
    var valueArr = value.split("")
    var decArr = []
    var answer = 0
    var n = 0

    valueArr.reverse()

    for (let i = 0; i < valueArr.length; i++) {
      switch (valueArr[i]) {
        case "1":
          decArr[i] = 1

          break

        case "2":
          decArr[i] = 2

          break

        case "3":
          decArr[i] = 3
          break
        case "4":
          decArr[i] = 4
          break
        case "5":
          decArr[i] = 5
          break
        case "6":
          decArr[i] = 6
          break
        case "7":
          decArr[i] = 7
          break
        case "8":
          decArr[i] = 8
          break
        case "9":
          decArr[i] = 9
          break
        case "A":
          decArr[i] = 10
          break
        case "B":
          decArr[i] = 11
          break
        case "C":
          decArr[i] = 12
          break
        case "D":
          decArr[i] = 13
          break
        case "E":
          decArr[i] = 14
          break
        case "F":
          decArr[i] = 15
          break
        default:
          console.log("default")
      }
    }
    for (let i = 0; i < decArr.length; i++) {
      answer = answer + decArr[i] * Math.pow(16, n)
      n = n + 1
    }

    sethexResult("Hexadecimal : " + value + ", Decimal : " + answer)
  }

  function decimalToHex() {
    var value = Decimal
    var total = value
    var answer = ""
    var halfway = 0
    var n = 0

    while (total > 0) {
      answer = (total % 2) + answer
      total = Math.floor(total / 2)
    }

    var answerArr = answer.split("").reverse()
    var splitArr = []

    answerArr.unshift(0)

    for (let i = 1; i < answerArr.length; i = i + 1) {
      halfway = halfway + answerArr[i] * Math.pow(2, n)

      n = n + 1

      if (i % 4 === 0 || i === answerArr.length - 1) {
        splitArr.push(halfway)
        halfway = 0
        n = 0
      }
    }

    for (let j = 0; j < splitArr.length; j++) {
      if (splitArr[j] === 10) {
        splitArr[j] = "A"
      } else if (splitArr[j] === 11) {
        splitArr[j] = "B"
      } else if (splitArr[j] === 12) {
        splitArr[j] = "C"
      } else if (splitArr[j] === 13) {
        splitArr[j] = "D"
      } else if (splitArr[j] === 14) {
        splitArr[j] = "E"
      } else if (splitArr[j] === 15) {
        splitArr[j] = "F"
      }
    }

    var outputArr = splitArr.reverse().join("")

    setdecimalResult("Decimal : " + value + ", Hexadecimal : " + outputArr)
  }

  function binaryToHex() {
    if (/\b[01]+\b/.test(Binary)) {
      var value = Binary
      var answer = value
      var halfway = 0
      var n = 0

      var answerArr = answer.split("").reverse()
      var splitArr = []

      answerArr.unshift(0)

      for (let i = 1; i < answerArr.length; i = i + 1) {
        halfway = halfway + answerArr[i] * Math.pow(2, n)

        n = n + 1

        if (i % 4 === 0 || i === answerArr.length - 1) {
          splitArr.push(halfway)
          halfway = 0
          n = 0
        }
      }

      for (let j = 0; j < splitArr.length; j++) {
        if (splitArr[j] === 10) {
          splitArr[j] = "A"
        } else if (splitArr[j] === 11) {
          splitArr[j] = "B"
        } else if (splitArr[j] === 12) {
          splitArr[j] = "C"
        } else if (splitArr[j] === 13) {
          splitArr[j] = "D"
        } else if (splitArr[j] === 14) {
          splitArr[j] = "E"
        } else if (splitArr[j] === 15) {
          splitArr[j] = "F"
        }
      }

      var outputArr = splitArr.reverse().join("")

      setbinaryResult("Binary : " + value + ", Hexadecimal : " + outputArr)
    } else {
      setbinaryResult("")
    }
  }

  function hexToBinary() {
    var value = Hexadecimal
    var valueArr = value.split("")
    var decArr = []
    var answer = 0
    var n = 0

    valueArr.reverse()

    for (let i = 0; i < valueArr.length; i++) {
      switch (valueArr[i]) {
        case "1":
          decArr[i] = 1
          break
        case "2":
          decArr[i] = 2
          break
        case "3":
          decArr[i] = 3
          break
        case "4":
          decArr[i] = 4
          break
        case "5":
          decArr[i] = 5
          break
        case "6":
          decArr[i] = 6
          break
        case "7":
          decArr[i] = 7
          break
        case "8":
          decArr[i] = 8
          break
        case "9":
          decArr[i] = 9
          break
        case "A":
          decArr[i] = 10
          break
        case "B":
          decArr[i] = 11
          break
        case "C":
          decArr[i] = 12
          break
        case "D":
          decArr[i] = 13
          break
        case "E":
          decArr[i] = 14
          break
        case "F":
          decArr[i] = 15
          break
        default:
          console.log("default")
      }
    }
    for (let i = 0; i < decArr.length; i++) {
      answer = answer + decArr[i] * Math.pow(16, n)
      n = n + 1
    }

    var total = answer
    answer = ""

    while (total > 0) {
      answer = (total % 2) + answer
      total = Math.floor(total / 2)
    }

    sethexResult("Hexadecimal : " + value + ", Binary : " + answer)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Binary, Decimal and Hexadecimal conversion"
        description="decimal hexadecimal binary converter - Simple Conversion decimal, hexadecimal, binary, convert decimal to binary, decimal to Hexadecimal, binary to decimal, hexadecimal to binary, hexadecimal to decimal."
        keywords={[
          "decimal hexadecimal binary converter, Simple Conversion decimal, hexadecimal, binary , convert decimal to binary,decimal to Hexadecimal,binary to decimal, hexadecimal to binary, hexadecimal to decimal,decimal converter, hexadecimal converter, binary converter.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Decimal, Hexadecimal and Binary Converter</Title>
        <TabsPages>
          <ul>
            <li className={pageOpen.binary ? "onThisPage" : ""}>
              <button
                className="likeAButton"
                onClick={() => {
                  setPageOpen({
                    binary: true,
                    decimal: false,
                    hex: false,
                  })
                }}
              >
                Binary
              </button>
            </li>
            <li className={pageOpen.decimal ? "onThisPage" : ""}>
              <button
                className="likeAButton"
                onClick={() => {
                  setPageOpen({
                    binary: false,
                    decimal: true,
                    hex: false,
                  })
                }}
              >
                Decimal
              </button>
            </li>
            <li className={pageOpen.hex ? "onThisPage" : ""}>
              <button
                className="likeAButton"
                onClick={() => {
                  setPageOpen({
                    binary: false,
                    decimal: false,
                    hex: true,
                  })
                }}
              >
                Hexadecimal
              </button>
            </li>
          </ul>
        </TabsPages>
        {pageOpen.binary && (
          <div>
            <SubTitle>Convert Binary</SubTitle>
            <Field>
              <Control>
                <StaticButton>Binary</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  aria-label="binary"
                  value={Binary}
                  pattern="\b[01]+\b"
                  onInput={checkIfBinary}
                  onChange={Binarychange}
                  placeholder="binary"
                  borderColor={binaryError ? "red" : ""}
                  className="borderedRight"
                  type="number"
                />
              </Control>
            </Field>
            <Button
              className="mb-2"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={binaryToD}
            >
              Binary To Decimal
            </Button>
            <Button
              className="mb-2"
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={binaryToHex}
            >
              Binary To Hex
            </Button>
            <p className="h3 mt-3">{binaryResult}</p>
          </div>
        )}

        {pageOpen.decimal && (
          <div>
            <SubTitle>Convert Decimal</SubTitle>
            <Field>
              <Control>
                <StaticButton>Decimal</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  aria-label="decimal"
                  value={Decimal}
                  onChange={Decimalchange}
                  placeholder="decimal"
                  type="number"
                  className="borderedRight"
                />
              </Control>
            </Field>
            <Button
              className="mb-2"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={decimalToB}
            >
              Decimal To Binary
            </Button>
            <Button
              className="mb-2"
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={decimalToHex}
            >
              Decimal To Hex
            </Button>
            <p className="h3 mt-3">{decimalResult}</p>
          </div>
        )}
        {pageOpen.hex && (
          <div>
            <SubTitle>Convert Hexadecimal</SubTitle>
            <Field>
              <Control>
                <StaticButton>Hexadecimal</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  aria-label="hexadecimal"
                  value={Hexadecimal}
                  onChange={Hexadecimalchange}
                  placeholder="hexadecimal"
                  type="text"
                  className="borderedRight"
                />
              </Control>
            </Field>
            <Button
              className="mb-2"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={hexToBinary}
            >
              Hex To Binary
            </Button>
            <Button
              className="mb-2"
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={hexToDecimal}
            >
              Hex To Decimal
            </Button>
            <p className="h3 mt-3">{hexResult}</p>
          </div>
        )}

        <br />

        <h3>Binary To Decimal conversion</h3>
        <p>
          To convert binary (binary: 01101010) to decimal, take each digit from
          right to left and multiply it by the place value then added to running
          total.
          <br />
          <strong>
            {" "}
            0 × 1 = 0, add 0<br />
            1 × 2 = 2, add 2, get 2<br />
            0 × 4 = 0, add 0, get 2<br />
            1 × 8 = 8, add 8, get 10
            <br />
            0 × 16 = 0, add 0, get 10
            <br />
            1 × 32 = 32, add 32, get 42
            <br />
            1 × 64 = 64, add 64, get 106
            <br />
            0 × 128 = 0, add 0, get 106.
            <br />
            The answer is that 01101010 binary = 106 decimal.
          </strong>
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default DecimalHexadecimalBinaryConverter
