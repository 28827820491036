const ops = {
  "+": (a, b) => a + b,
  "-": (a, b) => a - b,
  "×": (a, b) => a * b,
  "÷": (a, b) => Math.floor(a / b),
}

export function isBinary(num) {
  if (num !== "" && /\b[01]+\b/.test(num)) {
    return true
  } else {
    return false
  }
}

export function calculateBinary(num1, num2, operator) {
  if (num1 && num2 !== "") {
    const [n1, n2] = [parseInt(num1, 2), parseInt(num2, 2)]
    return Number(ops[operator](n1, n2)).toString(2)
  }
}

export function binaryToDecimal(Binary) {
  if (/\b[01]+\b/.test(Binary)) {
    let value = Binary
    let valueArr = value.split("")
    let answer = 0
    let n = 0
    valueArr.reverse()
    for (let i = 0; i < valueArr.length; i++) {
      answer = answer + valueArr[i] * Math.pow(2, n)
      n = n + 1
    }
    return answer
  } else return ""
}

export function decimalToBinary(number) {
  var binary = ""
  var temp = number
  while (temp > 0) {
    if (temp % 2 === 0) {
      binary = "0" + binary
    } else {
      binary = "1" + binary
    }
    temp = Math.floor(temp / 2)
  }

  return binary
}
